<template>
  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>Terms & Conditions</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }" class="cta-btn">Home</router-link></li>
          <li>Terms & Conditions</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= T&C Section ======= -->
    <section id="services" class="services ">
      <div class="container">
        <h2>Terms and Conditions of Use</h2>

        <h3>1. USE OF THIS WEBSITE</h3>
        By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use and agree that you are responsible for compliance
        with any applicable local laws and regulations.<br>
        <br>
        The web site is the sole property of Mt Marrow Blue Metal Quarries Pty Ltd (Mt Marrow). The materials contained on this web site are protected by applicable
        copyright and trademark law.<br>
        <br>
        You may store on your computer and print copies of the contents of this web site for your personal, non-commercial use. Otherwise, you may not reproduce,
        transfer, adapt, amend or store any of the contents of this web site, or incorporate any part of this web site into another web site, without the written
        consent of Mt Marrow.<br>
        <br>
        <br>
        <h3>2. DISCLAIMER</h3>
        Mt Marrow uses all reasonable efforts to ensure the accuracy of the contents of this web site but takes no responsibility for any errors or omissions therein
        and does not warrant or make any representations concerning the reliability or likely outcomes from the use of these materials or of any sites linked to this site.<br>
        <br>
        Mt Marrow may make changes to the materials contained on its web site at any time, without notice. It does not, however, make any commitment to update the materials.<br>
        <br>
        Mt Marrow makes no warranties, expressed or implied and hereby disclaims and negates all other warranties, including without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.<br>
        <br>
        To the extent permitted by law, Mt Marrow will not be liable in any way for any loss or damage (including loss of use, data or profits) suffered by you through
        use of, or access to, this web site.<br>
        <br>
        <br>
        <h3>3. CHANGES TO TERMS AND CONDITIONS OF USE</h3>
        Mt Marrow reserves the right to amend these Terms and Conditions of Use from time to time and you agree to be bound by these Terms and Conditions of Use, as
        amended. Mt Marrow will give you notice of these changes by publishing revised Terms and Conditions of Use on this web site. You will not be separately notified
        of these changes.<br>
        <br>
        <br>
        <h3>4. LINKS TO OTHER WEBSITES</h3>
        Mt Marrow is not responsible for the contents of any site linked to this site. The inclusion of any link is provided as a simple navigation tool and does not
        imply endorsement by Mt Marrow of the linked site. Use of any such linked web site is at the user’s own risk.<br>
        <br>
        <br>
        <h3>5. GOVERNING LAW</h3>
        This web site and any disputes about the content of this web site will be governed by the laws of Queensland, Australia.<br>
        <br>
        <br>
        <h3>6. PRIVACY POLICY</h3>
        Your privacy is very important to us and at all times, Mt Marrow will seek to act in accordance with current Privacy Laws. Accordingly, Mt Marrow has developed
        its own Privacy Policy to clarify how personal information is collected, used or otherwise communicated and this policy can be viewed
        <a href="/docs/Mt_Marrow_Privacy_Policy.pdf">here</a>.

      </div>
    </section>
  </main>
</template>
